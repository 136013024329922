<template>
  <GenericConnectionData
      :connectionId="connectionId"
      :title="title"
      :type="type"
      :breadcrumbsItems="breadcrumbsItems"
      @update-connection="updateConnection"
  >
    <template v-slot:request-components>
      <div class="col-lg-3 col-md-4">
        <AkeneoProductSearchDataRequest
            id="product-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.product_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoProductModelSearchDataRequest
            id="product-model-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.product_model_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoAttributeSearchDataRequest
            id="attribute-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.attribute_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoAttributeOptionDataRequest
            id="attribute-options"
            class="mt-3"
            :title="$t('connection.data.akeneo.attribute_options')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoAssetFamiliesDataRequest
            id="asset-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.asset_families')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoAssetAttributesDataRequest
            id="asset-attributes"
            class="mt-3"
            :title="$t('connection.data.akeneo.asset_family_attributes')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoAssetAttributeOptionsDataRequest
            id="asset-attribute-options"
            class="mt-3"
            :title="$t('connection.data.akeneo.asset_family_attribute_options')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoAssetSearchDataRequest
            id="asset-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.asset_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoRefEntitiesDataRequest
            id="ref-entities"
            class="mt-3"
            :title="$t('connection.data.akeneo.ref_entities')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoRefEntityAttributeDataRequest
            id="ref-entity-attributes"
            class="mt-3"
            :title="$t('connection.data.akeneo.ref_entity_attributes')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoRefEntitySearchDataRequest
            id="ref-entity-record-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.ref_entity_record_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoFamilySearchDataRequest
            id="family-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.family_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoFamilyVariantsDataRequest
            id="family-variants"
            class="mt-3"
            :title="$t('connection.data.akeneo.family_variants')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoCategorySearchDataRequest
            id="category-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.category_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoLocaleSearchDataRequest
            id="locale-search"
            class="mt-3"
            :title="$t('connection.data.akeneo.locale_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <AkeneoChannelsDataRequest
            id="channels"
            class="mt-3"
            :title="$t('connection.data.akeneo.channels')"
            :connection="connection"
        />
      </div>
    </template>
  </GenericConnectionData>
</template>

<script>
import {useToast} from "vue-toastification";
import securityMixin from "@/mixins/securityMixin";
import AkeneoAttributeOptionDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoAttributeOptionDataRequest.vue";
import AkeneoAttributeSearchDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoAttributeSearchDataRequest.vue";
import AkeneoRefEntitySearchDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoRefEntitySearchDataRequest.vue";
import AkeneoAssetSearchDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoAssetSearchDataRequest.vue";
import AkeneoProductModelSearchDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoProductModelSearchDataRequest.vue";
import AkeneoProductSearchDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoProductSearchDataRequest.vue";
import GenericConnectionData from "@/views/Connection/Data/GenericConnectionData.vue";
import AkeneoRefEntitiesDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoRefEntitiesDataRequest.vue";
import AkeneoRefEntityAttributeDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoRefEntityAttributeDataRequest.vue";
import AkeneoAssetFamiliesDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoAssetFamiliesDataRequest.vue";
import AkeneoAssetAttributesDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoAssetAttributesDataRequest.vue";
import AkeneoAssetAttributeOptionsDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoAssetAttributeOptionsDataRequest.vue";
import AkeneoFamilySearchDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoFamilySearchDataRequest.vue";
import AkeneoCategorySearchDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoCategorySearchDataRequest.vue";
import AkeneoFamilyVariantsDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoFamilyVariantsDataRequest.vue";
import AkeneoLocaleSearchDataRequest
  from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoLocaleSearchDataRequest.vue";
import AkeneoChannelsDataRequest from "@/views/Connection/Data/Akeneo/DataRequest/AkeneoChannelsDataRequest.vue";

export default {
  name: "AkeneoConnectionData",
  components: {
    AkeneoChannelsDataRequest,
    AkeneoLocaleSearchDataRequest,
    AkeneoFamilyVariantsDataRequest,
    AkeneoCategorySearchDataRequest,
    AkeneoFamilySearchDataRequest,
    AkeneoAssetAttributeOptionsDataRequest,
    AkeneoAssetAttributesDataRequest,
    AkeneoAssetFamiliesDataRequest,
    AkeneoRefEntityAttributeDataRequest,
    AkeneoRefEntitiesDataRequest,
    GenericConnectionData,
    AkeneoAttributeOptionDataRequest,
    AkeneoAttributeSearchDataRequest,
    AkeneoRefEntitySearchDataRequest,
    AkeneoAssetSearchDataRequest,
    AkeneoProductModelSearchDataRequest,
    AkeneoProductSearchDataRequest,
  },
  mixins: [securityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionId: String,
    title: String,
    type: String,
    breadcrumbsItems: Object
  },
  methods: {
    updateConnection(updatedConnection) {
      this.connection = updatedConnection;
    },
  },
  data () {
    return {
      connection: {},
    }
  },
}
</script>

<style scoped>

</style>
