<template>
  <div class="mt-3 row">
    <TagField
        :readOnly="allSelected"
        :addOnKey="[13]"
        :placeholder="placeholder"
        class="col-10"
        v-model="valueArray"
        :tooltip=tagTooltip
        :tooltip-placement="tagTooltipPlacement"
    />
    <div class="mt-2 col-2">
      <div
          ref="toggleInput"
          data-bs-toggle="tooltip"
          :data-bs-placement="toggleTooltipPlacement"
          :title="toggleTooltip"
      >
        <Toggle
            v-model="allSelected"
            on-label="All"
            off-label="All"
        />
      </div>
    </div>
  </div>
</template>

<script>

import TagField from "@/components/Form/TagField";
import Toggle from "@vueform/toggle";
import {Tooltip} from "bootstrap";

export default {
  name: "LocaleAndScopeField",
  components: {
    TagField,
    Toggle
  },
  props: {
    modelValue: [String, Array],
    placeholder: String,
    tagTooltip: String,
    tagTooltipPlacement: {
      type: String,
      default: "right"
    },
    toggleTooltip: String,
    toggleTooltipPlacement: {
      type: String,
      default: "right"
    },
  },
  data () {
    return {
      confInputKey: Math.random()
    }
  },
  computed: {
    valueArray: {
      get () {
        if (this.modelValue && this.modelValue !== "*") {
          return Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue];
        } else {
          return [];
        }
      },
      set (v) {
        this.$emit('update:modelValue', v);
      }
    },
    allSelected: {
      get () {
        return this.modelValue && this.modelValue === "*";
      }, set (v) {
        if (v) {
          this.$emit('update:modelValue', '*');
        } else {
          this.$emit('update:modelValue', null);
        }
      }
    }
  },
  mounted () {
    const inputElement = this.$refs.toggleInput;
    new Tooltip(inputElement);
  },
}
</script>
