<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="assetFamily"
          class="w-100"
          :label="$t('connection.data.akeneo.asset_family')"
          v-model="assetFamily"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";
import {useToast} from "vue-toastification";

export default {
  name: "AkeneoAssetAttributesDataRequest",
  components: {
    TextField,
    GenericDataRequest,
  },
  setup() {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      assetFamily: '',
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      if(this.assetFamily === '') {
        this.toast.error(this.$t('connection.data.akeneo.invalid_asset_family'));
      } else {
        return ConnectionService.getAkeneoData(this.connection.id, 'asset_family_attributes', {
          assetFamily: this.assetFamily,
        })
      }
    }
  },
}
</script>

