<template>
  <div class="d-flex"
       ref="toggleInput"
       data-bs-toggle="tooltip"
       :data-bs-placement="tooltipPlacement"
       :title="tooltip"
  >
    <div class="me-auto">
      <label :class="'form-label toggle-label ' + labelClass">
        {{ label }}
      </label>
    </div>
    <div>
      <Toggle
          v-model="toggleValue"
          @change="emitUpdate"
          on-label="Yes"
          off-label="No"
      />
    </div>
  </div>
</template>

<script>
import Toggle from '@vueform/toggle'
import {Tooltip} from "bootstrap";

export default {
  name: "ToggleField",
  components: {Toggle},
  props: {
    modelValue: Boolean,
    label: String,
    labelClass: {
      type: String,
      default: ''
    },
    tooltip: String,
    tooltipPlacement: {
      type: String,
      default: "right"
    },
  },
  data () {
    return {
      toggleValue: this.modelValue
    }
  },
  methods: {
    emitUpdate (value) {
      this.$emit(`update:modelValue`, value);
    }
  },
  mounted () {
    const inputElement = this.$refs.toggleInput;
    new Tooltip(inputElement);
  },
}
</script>

<style scoped>

</style>
