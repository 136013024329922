<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="form-label">
      {{ required ? label + '*' : label}}
    </label>
    <div class="mb-3"
        ref="tagsInput"
        data-bs-toggle="tooltip"
        :data-bs-placement="tooltipPlacement"
        :title="tooltip"
    >
      <vue-tags-input
        v-model="tag"
        :disabled="readOnly"
        :id="id"
        :tags="tags"
        :validation="validation"
        :add-on-key="addOnKey"
        :placeholder="placeholder"
        @tags-changed="onTagsChanged"
        class="form-control"
      />
    </div>
    <div v-if="error" class="invalid-feedback d-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@sipec/vue3-tags-input';
import {ref} from "vue";
import {Tooltip} from "bootstrap";

export default {
  name: "TagField",
  components: {
    VueTagsInput,
  },
  data () {
    return {
      tag: '',
      validation: this.checkIsEmail ? [{
        classes: 'is-email',
        rule: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      }] : [],
    };
  },
  computed: {
    tags: {
      get () {
        return this.modelValue ? this.modelValue.map((tag) => {
          return {text: tag}
        }) : [];
      }
    }
  },
  props: {
    id: String,
    label: String,
    placeholder: {
      type: String,
      default: ""
    },
    modelValue: Array,
    error: String,
    addOnKey: Array,
    tooltip: String,
    tooltipPlacement: {
      type: String,
      default: "right"
    },
    required: {
      type: Boolean,
      default: false
    },
    checkIsEmail: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTagsChanged (tags) {
      let modelTags = tags.map((tag) => {
        return tag.text;
      })

      this.$emit(`update:modelValue`, ref(modelTags));
    }
  },
  mounted () {
    const inputElement = this.$refs.tagsInput;
    new Tooltip(inputElement);
  },
}
</script>

<style lang="scss">
.ti-input {
  border: none !important;
  padding: 0 !important;
}

.vue-tags-input {
  max-width: none !important;
}

.ti-input input:focus {
  box-shadow: none;
}
</style>
