<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <NumberField
          id="page"
          class="w-100"
          :label="$t('connection.data.akeneo.page')"
          v-model="page"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import NumberField from "@/components/Form/NumberField.vue";

export default {
  name: "AkeneoChannelsDataRequest",
  components: {
    NumberField,
    GenericDataRequest,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      page: 1,
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getAkeneoData(this.connection.id, 'channels', {
        page: this.page
      })
    }
  },
}
</script>

