<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="assetFamily"
          class="w-100"
          :label="$t('connection.data.akeneo.asset_family')"
          v-model="assetFamily"
      />
      <p>{{ $t('connection.data.search') }}</p>
      <p>
        <a href="https://api.akeneo.com/documentation/filter.html#filter-assets" target="_blank">{{$t('connection.data.akeneo.details_link')}}</a>.
      </p>
      <p>
        Examples:
        <ul>
          <li>{"code":[{"operator":"IN","value":["code1","code2"]}]}</li>
        </ul>
      </p>
      <JsonField
          class=""
          id="search"
          :rows=3
          :modes="['code']"
          v-model="search"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import JsonField from "@/components/Form/JsonField.vue";
import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";
import {useToast} from "vue-toastification";

export default {
  name: "AkeneoAssetSearchDataRequest",
  components: {
    TextField,
    GenericDataRequest,
    JsonField,
  },
  setup() {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      search: '{}',
      assetFamily: '',
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      if(this.assetFamily === '') {
        this.toast.error(this.$t('connection.data.akeneo.invalid_asset_family'));
      } else {
        return ConnectionService.getAkeneoData(this.connection.id, 'asset_search', {
          search: this.search,
          assetFamily: this.assetFamily,
        })
      }
    }
  },
}
</script>

