<template>
  <div class="w-75">
    <ToggleField
        :id="type + 'display_id'"
        labelClass="fs-6 fw-bold"
        :label="$t('form.akeneoBynder.identifier.' + this.type + '.send_identifier')"
        :error="getErrorMessage('display_id')"
        v-model="hasIdentifierConfig"
    />
    <div v-if="hasIdentifierConfig">
      <TextField
          class=""
          :id="type + 'label_attribute_code'"
          :required=true
          :error="getErrorMessage('label_attribute')"
          :label="$t('form.akeneoBynder.identifier.label_attribute')"
          :tooltip="$t('form.akeneoBynder.identifier.label_attribute.tooltip')"
          v-model="label_attribute"
      />
      <TextField
          class=""
          :id="type + 'label_attribute_channel'"
          :required=false
          :error="getErrorMessage('channel')"
          :label="$t('form.akeneoBynder.identifier.channel')"
          :tooltip="$t('form.akeneoBynder.identifier.channel.tooltip')"
          v-model="channel"
      />
      <TagField
          :addOnKey="[13, ' ', ',', ';']"
          :label="$t('form.akeneoBynder.identifier.locales')"
          :id="type + 'label_attribute_locales'"
          v-model="locales"
          :required="false"
          :error="getErrorMessage('locales')"
          :placeholder="$t('form.akeneoBynder.identifier.locales.placeholder')"
          :tooltip="$t('form.akeneoBynder.identifier.locales.tooltip')"
      />
      <TextField
          class=""
          :id="type + 'bynder_property'"
          :required=true
          :error="getErrorMessage('bynder_property')"
          :label="$t('form.akeneoBynder.identifier.bynder_property')"
          :tooltip="$t('form.akeneoBynder.identifier.bynder_property.tooltip')"
          v-model="bynder_property"
      />
      <ToggleField
          class="mb-3"
          :id="type + 'display_id'"
          :label="$t('form.akeneoBynder.identifier.' + type + '.display_id')"
          :tooltip="$t('form.akeneoBynder.identifier.' + type + '.display_id.tooltip')"
          :error="getErrorMessage('display_id')"
          v-model="display_id"
      />
    </div>
  </div>
</template>

<script>

import TextField from "@/components/Form/TextField";
import ToggleField from "@/components/Form/ToggleField.vue";
import TagField from "@/components/Form/TagField.vue";

export default {
  name: "ProductIdentifierToBynderOptionField",
  components: {
    TagField,
    ToggleField,
    TextField,
  },
  props: {
    id: String,
    modelValue: Object,
    errors: Object,
    type: {
      type: String,
      default: 'product'
    }
  },
  data () {
    return {
      hasIdentifierConfig: !!this.modelValue && Object.keys(this.modelValue).length > 0,
      label_attribute: this.modelValue.label_attribute,
      bynder_property: this.modelValue.bynder_property,
      locales: this.modelValue.locales,
      channel: this.modelValue.channel,
      display_id: this.modelValue.display_id,
      formErrors: {},
    }
  },
  watch: {
    hasIdentifierConfig (newValue) {
      if (!newValue) {
        this.resetFields();
        this.$emit("update:modelValue", {});
      } else {
        this.updateModel();
      }
    },
    label_attribute: "updateModel",
    bynder_property: "updateModel",
    locales: "updateModel",
    channel: "updateModel",
    display_id: "updateModel",
  },
  methods: {
    updateModel () {
      if (!this.hasIdentifierConfig) {
        this.$emit("update:modelValue", {});
      } else {
        const model = {
          label_attribute: this.label_attribute,
          bynder_property: this.bynder_property,
          locales: this.locales,
          channel: this.channel,
          display_id: this.display_id,
        };
        this.$emit("update:modelValue", model);
      }
    },
    resetFields () {
      this.label_attribute = "";
      this.bynder_property = "";
      this.locales         = [];
      this.channel         = "";
      this.display_id      = false;
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
  },
  mounted () {
  },
}
</script>
